/* eslint-disable max-len */
import React, { FC, useEffect, useState } from 'react'
import FormWrapper from 'core/components/FormWrapper'
import Wizard from 'core/components/wizard/Wizard'
import useReactRouter from 'use-react-router'
import { pathJoin } from 'utils/misc'
import { k8sPrefix } from 'app/constants'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import { routes } from 'core/utils/routes'
import { ClusterCreateTypes, ClusterCreateTypeNames } from '../model'
import { getFormTitle } from '../helpers'
import DocumentMeta from 'core/components/DocumentMeta'
import { awsClusterTracking } from '../tracking'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createCluster } from '../newActions'
import useCapiResourceUpdate from './capi/useCapiResourceUpdate'
import { CapiResourceKind } from 'app/plugins/infrastructure/components/clusters/aws/capi/model'

const listUrl = pathJoin(k8sPrefix, 'infrastructure')

const wizardMetaFormattedNames = {
  name: 'Cluster Name',
  region: 'Region',
  kubeRoleVersion: 'K8s Version',
  numMasters: 'Masters',
  numWorkers: 'Workers',
  masterFlavor: 'Master Instance Type',
  workerFlavor: 'Worker Instance Type',
}
const wizardMetaCalloutFields = Object.keys(wizardMetaFormattedNames)

const templateFileNames = {
  [ClusterCreateTypes.Capi]: 'CapiAwsClusters',
  [ClusterCreateTypes.Custom]: 'AdvancedAwsClusters',
  [ClusterCreateTypes.EksCapi]: 'CapiEksClusters',
}

const AddAwsClusterPage = () => {
  const { history, match } = useReactRouter()
  const createType = match?.params?.type || ClusterCreateTypes.Custom
  const [activeView, setActiveView] = useState<{ ViewComponent: FC<any> }>(null)
  const [formTitle, setFormTitle] = useState<string>('')
  const [initialContext, setInitialContext] = useState(null)
  const segmentTrackingFields = {
    platform: CloudProviders.Aws,
    target: createType,
  }

  useEffect(() => {
    async function loadFile(name) {
      // Future-proof IDE refactoring by not using variables as filenames
      const fileName = templateFileNames[name]
      const view = await import(`./create-templates/${fileName}`)

      setActiveView({ ViewComponent: view.default })
      setFormTitle(ClusterCreateTypeNames[name])
      setInitialContext(view.initialContext)
    }

    loadFile(createType)
  }, [createType])

  useEffect(() => {
    awsClusterTracking.createStarted(segmentTrackingFields)()
  }, [])

  const {
    updating: creatingAwsCluster,
    update: createAwsCluster,
    error: errorCreatingAwsCluster,
  } = useUpdateAction(createCluster)

  const {
    updating: creatingCapiCluster,
    execute,
    errors: errorsCreatingCapiCluster,
    message: creatingCapiClusterMessage,
    results,
  } = useCapiResourceUpdate()

  const handleSubmit = async (data) => {
    const { success, response: result } = await createAwsCluster({
      ...data,
      clusterType: CloudProviders.Aws,
      segmentTrackingFields,
    })

    if (success) {
      const { uuid: clusterId } = result
      history.push(routes.cluster.legacy.detail.path({ tab: 'node-health', id: clusterId }))
    }
  }

  const createCapiAwsCluster = async (data, schemas) => {
    const { errors, results } = await execute(schemas)
    if (!errors?.length && results?.length) {
      const uuid = results.find((result) => result.kind === CapiResourceKind.Cluster)?.metadata.uid
      history.push(routes.cluster.capi.details.path({ id: uuid, tab: 'overview' }))
    }
  }

  const ViewComponent = activeView?.ViewComponent
  return (
    <FormWrapper
      backUrl={listUrl}
      loading={creatingAwsCluster || creatingCapiCluster}
      message={creatingCapiClusterMessage}
    >
      <DocumentMeta title={formTitle} bodyClasses={['form-view']} />
      {!!initialContext && (
        <Wizard
          error={errorCreatingAwsCluster}
          errors={errorsCreatingCapiCluster}
          onComplete={
            createType === ClusterCreateTypes.Capi || createType === ClusterCreateTypes.EksCapi
              ? createCapiAwsCluster
              : handleSubmit
          }
          context={initialContext}
          originPath={routes.cluster.add.root.path({ type: CloudProviders.Aws })}
          showExitConfirmationDialog
        >
          {({ wizardContext, setWizardContext, onNext }) => (
            <>
              {ViewComponent && (
                <ViewComponent
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  onNext={onNext}
                  summaryTitle={getFormTitle(formTitle, createType)}
                  summaryIcon={CloudProviders.Aws}
                  summaryKeyOverrides={wizardMetaFormattedNames}
                  summaryCalloutFields={wizardMetaCalloutFields}
                />
              )}
            </>
          )}
        </Wizard>
      )}
    </FormWrapper>
  )
}
export default AddAwsClusterPage
