import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import { ClusterTypes } from 'app/plugins/infrastructure/components/clusters/model'
import Text from 'core/elements/Text'
import Tooltip from 'core/elements/tooltip'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import { isTransientStatus } from 'app/plugins/infrastructure/components/clusters/ClusterStatusUtils'
import React from 'react'
import { CombinedClusterSelector } from '../../combinedClusters/model'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/core/styles'
import { switchCase } from 'utils/fp'
import clsx from 'clsx'

const getTooltipText = ({
  upgradeAvailable = false,
  upgradeFailed = false,
  deprecatedVersion = false,
}) => {
  if (upgradeFailed) {
    return 'Upgrade process failed'
  } else if (upgradeAvailable) {
    return 'Upgrade Available'
  } else if (deprecatedVersion) {
    return 'Cluster is running deprecated version'
  } else {
    return null
  }
}

const QbertClusterUpgradeLinks = ({ cluster }) => {
  const classes = useStyles()
  const { version, canUpgrade, upgradingTo } = cluster
  const upgradeAvailable = canUpgrade && !upgradingTo
  const tooltipText = getTooltipText({ upgradeAvailable })
  const showIcon = !!version && upgradeAvailable
  return (
    <>
      <div className={classes.versionContainer}>
        <Text variant="body2">{version}</Text>
        {showIcon && (
          <Tooltip message={tooltipText}>
            <FontAwesomeIcon className={clsx(classes.icon, { upgradeAvailable })}>
              exclamation-circle
            </FontAwesomeIcon>
          </Tooltip>
        )}
      </div>
    </>
  )
}

const CapiClusterUpgradeLinks = ({ cluster }) => {
  const classes = useStyles()
  const {
    version,
    canUpgrade,
    phase,
    upgrading,
    upgradeFailed,
    infrastructureReady,
    controlPlaneReady,
  } = cluster
  const upgradeAvailable =
    canUpgrade &&
    !isTransientStatus((phase || '').toLowerCase()) &&
    infrastructureReady &&
    controlPlaneReady
  const tooltipText = getTooltipText({ upgradeAvailable, upgradeFailed })
  const showIcon = (!!version && upgradeAvailable) || upgradeFailed
  return (
    <>
      {upgrading ? (
        <div className={classes.upgradingContainer}>
          <FontAwesomeIcon size="md" className={classes.spinner} spin>
            circle-notch
          </FontAwesomeIcon>
          <Text variant="body2">Upgrading</Text>
          <SimpleLink
            src={routes.cluster.capi.upgradeProgress.path({
              id: cluster.uuid,
              jobId: cluster?.currentUpgradeJob?.id,
            })}
          >
            See more
          </SimpleLink>
        </div>
      ) : (
        <div className={classes.versionContainer}>
          <Text variant="body2">{version}</Text>
          {showIcon && (
            <Tooltip message={tooltipText}>
              <FontAwesomeIcon className={clsx(classes.icon, { upgradeAvailable, upgradeFailed })}>
                exclamation-circle
              </FontAwesomeIcon>
            </Tooltip>
          )}
        </div>
      )}
    </>
  )
}

const ImportedClusterK8sVersionCell = ({ cluster }) => (
  <Text variant="body2">{cluster?.version}</Text>
)

export default function K8sVersionCell({ item: cluster }: GridCellProps<CombinedClusterSelector>) {
  const { clusterType, version } = cluster
  const VersionsComponent = switchCase({
    [ClusterTypes.Normal]: QbertClusterUpgradeLinks,
    [ClusterTypes.Imported]: ImportedClusterK8sVersionCell,
    [ClusterTypes.Capi]: CapiClusterUpgradeLinks,
  })(clusterType)
  return <>{!clusterType || !version ? null : <VersionsComponent cluster={cluster} />}</>
}

const useStyles = makeStyles<Theme>((theme) => ({
  versionContainer: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gridGap: theme.spacing(1),
  },
  icon: {
    '&.upgradeAvailable': {
      color: theme.palette.blue.main,
    },
    '&.upgradeFailed': {
      color: theme.palette.red.main,
    },
    '&.depreciatedVersion': {
      color: theme.palette.yellow.main,
    },
  },
  upgradingContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'baseline',
  },
  spinner: {
    color: theme.palette.blue.main,
  },
}))
