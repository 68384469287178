import React, { FC, useCallback, useEffect } from 'react'
import ExternalLink from 'core/components/ExternalLink'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import {
  CloudDefaults,
  CloudProviders,
} from 'app/plugins/infrastructure/components/cloudProviders/model'
import { awsPrerequisitesLink } from 'k8s/links'
import ClusterNameField from '../../form-components/NameField'
import AwsClusterSshKeyPickList from '../AwsClusterSshKeyPicklist'
import WizardStep from 'core/components/wizard/WizardStep'
import FormReviewTable from 'core/components/validatedForm/review-table'
import { UserPreferences } from 'app/constants'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import CloudProviderRegionField from '../../form-components/CloudProviderRegionField'
import SshKeyField from '../../form-components/SshKeyPicklist'
import ClusterDomainField from '../../form-components/ClusterDomainField'
import AwsAvailabilityZoneField from '../AwsAvailabilityZone'
import { castBoolToStr } from 'utils/misc'
import KubernetesVersionField from '../../form-components/KubernetesVersionField'
import { awsClusterTracking } from '../../tracking'
import { ClusterCreateTypes } from '../../model'
import { CalicoDetectionTypes } from '../../form-components/CalicoNetworkFields'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { options } from './AdvancedAwsClusters'
import { emptyObj, isNilOrEmpty } from 'utils/fp'
import { compareVersions } from 'k8s/util/helpers'
import { oneClickEtcdBackupDefaults } from '../../cluster-addons/etcd-backup'
import CloudProviderPicklist from 'k8s/components/common/CloudProviderPicklist'
import PicklistField from 'core/components/validatedForm/DropdownField'

export const initialContext = {
  containersCidr: '10.20.0.0/22',
  servicesCidr: '10.21.0.0/22',
  network: 'newPublic',
  runtimeConfigOption: 'default',
  networkPlugin: 'calico',
  ami: 'ubuntu',
  calicoIpIpMode: 'Always',
  calicoNatOutgoing: true,
  calicoBlockSize: '26',
  mtuSize: 1440,
  etcdBackup: true,
  prometheusMonitoringEnabled: true,
  usePf9Domain: false,
  networkStack: 'ipv4',
  privileged: true,
  calicoDetectionMethod: CalicoDetectionTypes.FirstFound,
  azs: [],
  domainId: '',
  enableProfileAgent: true,
  containerRuntime: 'containerd',
  ...options.small,
  ...oneClickEtcdBackupDefaults,
}

export const columns = [
  { id: 'numMasters', label: 'Master nodes' },
  { id: 'numWorkers', label: 'Worker nodes' },
  { id: 'masterFlavor', label: 'Master node instance type' },
  { id: 'workerFlavor', label: 'Worker node instance type' },
  {
    id: 'allowWorkloadsOnMaster',
    label: 'Enable workloads on all master nodes',
    render: (value) => castBoolToStr()(value),
  },
  { id: 'ami', label: 'Operating System', insertDivider: true },
  { id: 'network', label: 'Network' },
  { id: 'calicoIpIpMode', label: 'IP in IP Encapsulation Mode' },
  { id: 'calicoNatOutgoing', label: 'NAT Outgoing', render: (value) => castBoolToStr()(value) },
  { id: 'calicoBlockSize', label: 'Block Size' },
  { id: 'mtuSize', label: 'MTU Size' },
  { id: 'containerRuntime', label: 'Container Runtime' },
  {
    id: 'etcdBackup',
    label: 'ETCD Backup',
    render: (value) => castBoolToStr()(value),
    insertDivider: true,
  },
  { id: 'etcdStoragePath', label: 'Storage Path' },
  { id: 'etcdBackupTimestamp', label: 'Daily Backup Time' },
  { id: 'maxTimestampBackups', label: 'Max Timestamp Backup Count' },
  {
    id: 'prometheusMonitoringEnabled',
    label: 'Enable monitoring with prometheus',
    render: (value) => castBoolToStr()(value),
  },
  {
    id: 'enableProfileAgent',
    label: 'Enable profile agent',
    render: (value) => castBoolToStr()(value),
  },
]

const trackingFields = {
  platform: CloudProviders.Aws,
  target: ClusterCreateTypes.OneClick,
}

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))

interface Props {
  wizardContext: any
  setWizardContext: any
  onNext: any
}

const OneClickAwsCluster: FC<Props> = ({ wizardContext, setWizardContext, onNext, ...rest }) => {
  const classes = useStyles({})
  const { prefs, fetchUserDefaults } = useScopedPreferences('defaults')
  const cloudDefaults =
    prefs?.[UserPreferences.CloudProviders]?.[wizardContext.cloudProviderId] || emptyObj

  useEffect(() => {
    // Load user defaults from the preference store
    fetchUserDefaults(UserPreferences.CloudProviders)
  }, [])

  useEffect(() => {
    if (compareVersions(wizardContext.kubeRoleVersion, '1.20') >= 0) {
      setWizardContext({ enableProfileAgent: true })
    } else {
      setWizardContext({ enableProfileAgent: false })
    }
  }, [wizardContext.kubeRoleVersion])

  const updateFqdns = (values, label) => (value) => {
    setWizardContext({ domainId: value.domainId })

    const name = values.name || wizardContext.name

    const api = `${name}-api.${value.label}` || `${name}-api.${label}`
    setWizardContext({ externalDnsName: api })

    const service = `${name}-service.${value.label}` || `${name}-service.${label}`
    setWizardContext({ serviceFqdn: service })
  }

  useEffect(() => {
    if (isNilOrEmpty(cloudDefaults)) return
    setCloudDefaultsInForm()
  }, [cloudDefaults])

  const setCloudDefaultsInForm = useCallback(() => {
    if (isNilOrEmpty(cloudDefaults)) return
    setWizardContext({ ...cloudDefaults })
    if (cloudDefaults[CloudDefaults.Domain]) {
      updateFqdns(
        cloudDefaults,
        cloudDefaults[CloudDefaults.DomainLabel],
      )(cloudDefaults[CloudDefaults.Domain])
    }
  }, [cloudDefaults])

  return (
    <WizardStep stepId="one-click" onNext={awsClusterTracking.oneClick(trackingFields)} {...rest}>
      <ValidatedForm
        classes={{ root: classes.validatedFormContainer }}
        fullWidth
        initialValues={wizardContext}
        triggerSubmit={onNext}
        elevated={false}
      >
        {({ setFieldValue, values }) => (
          <>
            <FormFieldSection
              title="Cluster Configuration"
              link={
                <ExternalLink textVariant="caption2" url={awsPrerequisitesLink}>
                  AWS Cluster Help
                </ExternalLink>
              }
            >
              {/* Cluster Name */}
              <ClusterNameField setWizardContext={setWizardContext} />

              {/* Cloud Provider */}
              <PicklistField
                id="cloudProviderId"
                DropdownComponent={CloudProviderPicklist}
                type={CloudProviders.Aws}
                value={wizardContext.cloudProviderId}
                onChange={(value) => setWizardContext({ cloudProviderId: value })}
                tooltip="Nodes will be provisioned using this cloud provider."
              />

              {/* Cloud Provider Region */}
              <CloudProviderRegionField
                cloudProviderType={CloudProviders.Aws}
                values={values}
                wizardContext={wizardContext}
                onChange={(region) => setWizardContext({ region, azs: [] })}
                disabled={!values.cloudProviderId && !values.region}
              />

              {/* AWS Availability Zone */}
              {values.region && (
                <AwsAvailabilityZoneField
                  values={values}
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  allowMultiSelect={false}
                />
              )}

              {/* SSH Key */}
              <SshKeyField
                dropdownComponent={AwsClusterSshKeyPickList}
                values={values}
                wizardContext={wizardContext}
                setWizardContext={setWizardContext}
              />

              {/* Cluster Domain */}
              <ClusterDomainField
                values={values}
                value={{ domainId: wizardContext.domainId }}
                onChange={updateFqdns(values, 'Domain')}
                required={false}
                disabled={wizardContext.usePf9Domain}
              />

              <KubernetesVersionField
                wizardContext={wizardContext}
                setWizardContext={setWizardContext}
              />
            </FormFieldSection>

            <FormFieldSection title="Default Settings for New Cluster">
              <FormReviewTable data={wizardContext} columns={columns} />
            </FormFieldSection>
          </>
        )}
      </ValidatedForm>
    </WizardStep>
  )
}

export default OneClickAwsCluster
