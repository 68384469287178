import { makeStyles } from '@material-ui/core/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import React from 'react'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Card from 'core/elements/card'
import { formatDate } from 'utils/misc'
import clsx from 'clsx'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'

const getHeaderText = (upgrading, upgradeFailed, upgradeCompleted) => {
  if (upgrading) {
    return 'Upgrade in progress'
  } else if (upgradeFailed) {
    return 'Upgrade Failed'
  } else if (upgradeCompleted) {
    return 'Upgrade Succeeded'
  }
  return ''
}

const getHeaderIcon = (upgrading, upgradeFailed, upgradeCompleted) => {
  if (upgrading) {
    return 'circle-notch'
  } else if (upgradeFailed) {
    return 'circle-xmark'
  } else if (upgradeCompleted) {
    return 'circle-check'
  }
  return ''
}

export default function CapiClusterUpgradeProgressCard({ cluster, className = undefined }) {
  const { history } = useReactRouter()
  const { upgrading, upgradeFailed, upgradeCompleted, recentUpgradeJob } = cluster
  const classes = useStyles({ upgrading, upgradeFailed, upgradeCompleted })

  return (
    <Card className={clsx(classes.upgradeProgressContainer, className)}>
      <div className={classes.content}>
        <div className={classes.horizontalContainer}>
          <FontAwesomeIcon size="lg" solid spin={upgrading} className={classes.icon}>
            {getHeaderIcon(upgrading, upgradeFailed, upgradeCompleted)}
          </FontAwesomeIcon>
          <Text variant="subtitle2">
            {getHeaderText(upgrading, upgradeFailed, upgradeCompleted)}
          </Text>
        </div>
        <div className={classes.horizontalContainer}>
          {!upgradeFailed && (
            <>
              <div className={classes.info}>
                <Text variant="body2">Completed:</Text>
                <Text variant="caption1">{`${recentUpgradeJob?.completedSteps} of 3 steps`}</Text>
              </div>
              <div className={classes.info}>
                <Text variant="body2">Current Version:</Text>
                <Text variant="caption1">
                  {upgrading ? recentUpgradeJob?.sourceVersion : cluster?.version}
                </Text>
              </div>
              <div className={classes.info}>
                <Text variant="body2">Target Version:</Text>
                <Text variant="caption1">{recentUpgradeJob?.targetVersion}</Text>
              </div>
              <div className={classes.info}>
                <Text variant="body2">Start Time:</Text>
                <Text variant="caption1">
                  {recentUpgradeJob?.startTime ? formatDate(recentUpgradeJob?.startTime) : 'N/A'}
                </Text>
              </div>
            </>
          )}
          {upgradeFailed && (
            <>
              <div className={classes.info}>
                <Text variant="body2">Reason:</Text>
                <Text variant="caption1">{recentUpgradeJob?.reason}</Text>
              </div>
              <div className={classes.info}>
                <Text variant="body2">Message:</Text>
                <Text variant="caption1">{recentUpgradeJob?.message}</Text>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={classes.button}>
        <Button
          variant="tertiary"
          onClick={() =>
            history.push(
              routes.cluster.capi.upgradeProgress.path({
                id: cluster?.uuid,
                jobId: recentUpgradeJob?.id,
              }),
            )
          }
        >
          See Details
        </Button>
      </div>
    </Card>
  )
}

const getIconColor = ({ upgrading, upgradeFailed, theme }) => {
  if (upgrading) {
    return theme.palette.blue.main
  } else if (upgradeFailed) {
    return theme.palette.red.main
  } else {
    return theme.palette.green.main
  }
}

const useStyles = makeStyles<
  Theme,
  { upgrading: boolean; upgradeFailed: boolean; upgradeCompleted: boolean }
>((theme) => ({
  upgradeProgressContainer: {
    height: '100px',
    width: '100%',
    '& .card-body': {
      display: 'grid',
      gridTemplateColumns: '1fr max-content',
    },
  },
  content: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  horizontalContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gridGap: theme.spacing(2),
    alignItems: 'baseline',
  },
  icon: {
    color: (props) => getIconColor({ ...props, theme }),
  },
  info: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  button: {
    display: 'flex',
    alignItems: 'end',
  },
}))
