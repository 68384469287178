import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Theme from 'core/themes/model'
import Divider from 'core/elements/Divider'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import WizardStep from 'core/components/wizard/WizardStep'
import { AddonManagerAddon, AddonsSection } from '../../cluster-addons/cluster-addon-manager'
import CapiSpec from './CapiSpec'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { listClusterVersions } from '../../cluster-addons/new-actions'
import { clusterVersionsSelector } from '../../cluster-addons/selectors'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { YamlTemplate } from 'core/components/wizard/YamlTemplates'
import monitoringJson from '../../cluster-addons/schema/monitoring.json'
import coreDnsJson from '../../cluster-addons/schema/coredns.json'
import profileAgentJson from '../../cluster-addons/schema/pf9-profile-agent.json'
import metricsServerJson from '../../cluster-addons/schema/metrics-server.json'
import kubernetesDashboardJson from '../../cluster-addons/schema/kubernetes-dashboard.json'
import capiAutoScalerJson from '../../cluster-addons/schema/cluster-auto-scaler-capi.json'
import {
  clusterAddonDisplayNames,
  sameMajorAndMinorVersion,
  getPatchVersion,
} from '../../cluster-addons/helpers'
import { compose, prop, sortBy } from 'ramda'
import { ClusterAddonType } from '../../cluster-addons/model'

const sortByPatchVersion = sortBy(compose(getPatchVersion, prop('version')))

// For EKS clusters, the kubernetes version doesn't match the ClusterVersion kubernetes version
// so we match the major & minor versions, and then take the ClusterVersion with newest patch version
export const getEksAddons = (version, clusterVersions) => {
  const strippedVersion = version[0] === 'v' ? version.substr(1) : version
  const potentialVersions = clusterVersions.filter((clusterVersion) => {
    return sameMajorAndMinorVersion(strippedVersion, clusterVersion.version)
  })
  // Todo: Add ClusterVersion typing and update this any
  const [latestVersion]: any = sortByPatchVersion(potentialVersions).slice(-1)
  return latestVersion?.addons
}

const yamlTemplates: YamlTemplate[] = [
  {
    title: clusterAddonDisplayNames[ClusterAddonType.ProfileAgent],
    schema: profileAgentJson,
    hide: (values) => !values.enableProfileAgent,
  },
  {
    title: clusterAddonDisplayNames[ClusterAddonType.MetricsServer],
    schema: metricsServerJson,
    hide: (values) => !values.enableMetricsServer,
  },
  {
    title: clusterAddonDisplayNames[ClusterAddonType.KubernetesDashboard],
    schema: kubernetesDashboardJson,
    hide: (values) => !values.enableKubernetesDashboard,
  },
  {
    title: clusterAddonDisplayNames[ClusterAddonType.CapiAutoScaler],
    schema: capiAutoScalerJson,
    hide: (values) => !values.enableCapiAutoScaler,
  },
  {
    title: clusterAddonDisplayNames[ClusterAddonType.Monitoring],
    schema: monitoringJson,
    hide: (values) => !values.prometheusMonitoringEnabled,
  },
  {
    title: clusterAddonDisplayNames[ClusterAddonType.CoreDns],
    schema: coreDnsJson,
    hide: (values) => !values.enableCoreDns,
  },
]

export default function CapiAddonsWizardStep({
  wizardContext,
  setWizardContext,
  onNext,
  isEksCluster = false,
}) {
  const classes = useStyles()

  const nonConfigurableAddons: AddonManagerAddon[] = [
    { addon: 'profileAgent' },
    { addon: 'metricsServer' },
    { addon: 'kubernetesDashboard' },
    { addon: 'capiAutoScaler' },
  ]

  const configurableAddons: AddonManagerAddon[] = isEksCluster
    ? [{ addon: 'monitoring' }]
    : [{ addon: 'monitoring' }, { addon: 'coreDns' }]

  const { loading } = useListAction(listClusterVersions)
  const clusterVersions = useSelector(clusterVersionsSelector)

  // Set addon versions
  useEffect(() => {
    if (wizardContext?.version) {
      const addOnVersions = isEksCluster
        ? getEksAddons(wizardContext.version, clusterVersions)
        : clusterVersions.find((clusterVersion) => clusterVersion.version === wizardContext.version)
            ?.addons
      const versionMap =
        addOnVersions?.reduce((accum, addon) => {
          return {
            ...accum,
            [addon.name]: addon.version,
          }
        }, {}) || {}
      setWizardContext({
        monitoringVersion: versionMap['monitoring'],
        corednsVersion: versionMap['coredns'],
        profileAgentVersion: versionMap['profileagent'],
        metricsServerVersion: versionMap['metricsserver'],
        kubernetesDashboardVersion: versionMap['dashboard'],
        // TODO: remove the 1.21.1 when backend adds cascapi
        capiAutoScalerVersion: versionMap['cascapi'] || '1.21.1',
      })
    }
  }, [clusterVersions, wizardContext?.version])

  // Special management of wizardContext fields to schemas
  // Don't like this very much, wonder how this could be changed
  // Currently only needed on coredns but will change with more configurable addons
  useEffect(() => {
    if (wizardContext.useEntireCoreDnsConfig) {
      setWizardContext({
        coreDnsParams: [
          {
            name: 'base64EncEntireDnsConfig',
            value: wizardContext.base64EncEntireDnsConfig,
          },
          {
            name: 'CoresPerReplica',
            value: wizardContext.CoresPerReplica,
          },
          {
            name: 'NodesPerReplica',
            value: wizardContext.NodesPerReplica,
          },
          {
            name: 'MinReplicas',
            value: wizardContext.MinReplicas,
          },
          {
            name: 'MaxReplicas',
            value: wizardContext.MaxReplicas,
          },
          {
            name: 'PollPeriodSecs',
            value: wizardContext.PollPeriodSecs,
          },
        ].filter((val) => !!val?.value),
      })
    } else {
      setWizardContext({
        coreDnsParams: [
          {
            name: 'dnsMemoryLimit',
            value: `${wizardContext.dnsMemoryLimit}Mi`,
          },
          {
            name: 'dnsDomain',
            value: wizardContext.dnsDomain,
          },
          {
            name: 'base64EncAdditionalDnsConfig',
            value: wizardContext.base64EncAdditionalDnsConfig,
          },
          {
            name: 'CoresPerReplica',
            value: wizardContext.CoresPerReplica,
          },
          {
            name: 'NodesPerReplica',
            value: wizardContext.NodesPerReplica,
          },
          {
            name: 'MinReplicas',
            value: wizardContext.MinReplicas,
          },
          {
            name: 'MaxReplicas',
            value: wizardContext.MaxReplicas,
          },
          {
            name: 'PollPeriodSecs',
            value: wizardContext.PollPeriodSecs,
          },
        ].filter((val) => !!val?.value),
      })
    }
  }, [
    wizardContext.useEntireCoreDnsConfig,
    wizardContext.base64EncEntireDnsConfig,
    wizardContext.dnsMemoryLimit,
    wizardContext.dnsDomain,
    wizardContext.base64EncAdditionalDnsConfig,
    wizardContext.CoresPerReplica,
    wizardContext.NodesPerReplica,
    wizardContext.MinReplicas,
    wizardContext.MaxReplicas,
    wizardContext.PollPeriodSecs,
  ])

  return (
    <WizardStep
      stepId="addons"
      label="Add-ons"
      yamlTemplates={yamlTemplates}
      // Todo: write tracking fn
      // onNext={awsClusterTracking.wZStepTwo(trackingFields)}
    >
      <ValidatedForm
        classes={{ root: classes.validatedFormContainer }}
        fullWidth
        initialValues={wizardContext}
        onSubmit={setWizardContext}
        triggerSubmit={onNext}
        elevated={false}
      >
        {({ values, setFieldValue }) => (
          <>
            <CapiSpec title="Non-configurable add-ons">
              <AddonsSection
                addons={nonConfigurableAddons}
                wizardContext={wizardContext}
                setWizardContext={setWizardContext}
                values={values}
                setFieldValue={setFieldValue}
                className={classes.nonConfigurableAddonsSection}
              />
            </CapiSpec>
            <Divider />
            <FormFieldSection title="Configurable add-ons" textVariant="caption1">
              <AddonsSection
                addons={configurableAddons}
                wizardContext={wizardContext}
                setWizardContext={setWizardContext}
                values={values}
                setFieldValue={setFieldValue}
              />
            </FormFieldSection>
          </>
        )}
      </ValidatedForm>
    </WizardStep>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  nonConfigurableAddonsSection: {
    margin: theme.spacing(0, 0, 3, 6),
  },
}))
