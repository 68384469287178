import { AppSelector } from 'app/store'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector, selectParamsFromProps } from 'core/utils/selectorHelpers'
import { ClusterTypes } from '../model'
import { capiAwsClustersByNamespaceAndNameSelector } from '../aws/capi/selectors'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import { nodeletControlPlanesByNamespaceAndNameSelector } from './control-plane/nodelet-control-plane/selectors'
import { awsManagedControlPlanesByNamespaceAndNameSelector } from './control-plane/aws-managed-control-plane/selectors'
import { switchCase, isNilOrEmpty, filterIf } from 'utils/fp'
import {
  Ami,
  AwsClusterTypes,
  CapiClusterPhases,
  IAwsAmiSelector,
  ICapiClusterSelector,
} from './model'
import { capitalizeString, castFuzzyBool } from 'utils/misc'
import { makeCloudProvidersSelector } from '../../cloudProviders/selectors'
import {
  getCapiK8sDashboardLink,
  getCapiGrafanaLink,
  getCloudProviderName,
  controlPlaneIsReady,
  sortStatusCondsBySeverity,
} from './helpers'
import { CapiResourceKind } from 'app/plugins/infrastructure/components/clusters/aws/capi/model'
import {
  clusterAddonsByClusterNameSelector,
  clusterVersionsSelector,
} from 'app/plugins/infrastructure/components/clusters/cluster-addons/selectors'
import { CapiClusterParams, ClusterParams } from '../../common/model'
import { createSelector } from '@reduxjs/toolkit'
import { pipe, propSatisfies, pluck, head, sortWith, descend, prop } from 'ramda'
import { ClusterAddonType } from '../cluster-addons/model'
import { machineDeploymentsByNamespaceAndClusterSelector } from './machine-deployment/selectors'
import { machinePoolsByNamespaceAndClusterSelector } from './machine-pool/selectors'
import { compareVersions } from 'k8s/util/helpers'
import { clusterUpgradeJobsByClusterSelector } from './upgrade/selectors'
import { kubevirtCluster } from 'app/plugins/infrastructure/components/clusters/helpers'
import { IClusterUpgradeJobSelector } from './upgrade/model'
import { eksVersionsSelector } from '../../versions/eks/selectors'

const getInfrastructureType = (infrastructureRefKind) =>
  switchCase(
    {
      AWSCluster: AwsClusterTypes.AWS,
      AWSManagedControlPlane: AwsClusterTypes.EKS,
    },
    '',
  )(infrastructureRefKind)

const getInfrastructureData = (infrastructureRef, awsClusters, awsManagedControlPlanes) => {
  const { kind, namespace, name } = infrastructureRef || {}
  if (kind === CapiResourceKind.AWSCluster) {
    return awsClusters[namespace]?.[name] || {}
  } else if (kind === CapiResourceKind.AWSManagedControlPlane) {
    return awsManagedControlPlanes[namespace]?.[name] || {}
  }
  return {}
}

const getControlPlaneData = (controlPlaneRef, nodeletcontrolPlanes, awsManagedControlPlanes) => {
  const { kind, namespace, name } = controlPlaneRef || {}
  if (kind === CapiResourceKind.NodeletControlPlane) {
    return nodeletcontrolPlanes[namespace]?.[name] || {}
  } else if (kind === CapiResourceKind.AWSManagedControlPlane) {
    return awsManagedControlPlanes[namespace]?.[name] || {}
  }
  return {}
}

const getCloudProvider = (identityRef, awsCloudProviders) => {
  if (isNilOrEmpty(identityRef) || isNilOrEmpty(awsCloudProviders)) return null
  const cloudProviderName = getCloudProviderName(identityRef.name)
  return awsCloudProviders.find((cp) => cp.name === cloudProviderName)
}

const hasProvisionedClusters = propSatisfies(
  (phase: string) => phase === CapiClusterPhases.Provisioned,
  'phase',
)

const awsCloudProvidersSelector = makeCloudProvidersSelector({ type: CloudProviders.Aws })

const getLatestClusterVersion = (versions, versionPropKey): string => {
  if (isNilOrEmpty(versions)) return null
  const sortedVersions = sortWith([descend(prop(versionPropKey))])(versions)
  return sortedVersions[0][versionPropKey] as string
}

export const capiClustersSelector: AppSelector<ICapiClusterSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.CapiClusters>(DataKeys.CapiClusters),
  capiAwsClustersByNamespaceAndNameSelector,
  nodeletControlPlanesByNamespaceAndNameSelector,
  awsManagedControlPlanesByNamespaceAndNameSelector,
  awsCloudProvidersSelector,
  clusterAddonsByClusterNameSelector,
  machineDeploymentsByNamespaceAndClusterSelector,
  machinePoolsByNamespaceAndClusterSelector,
  clusterVersionsSelector,
  eksVersionsSelector,
  clusterUpgradeJobsByClusterSelector,

  (
    rawCapiClusters,
    capiAwsClustersByNamespaceAndName,
    nodeletControlPlanesByNamespaceAndName,
    awsManagedControlPlanesByNamespaceAndName,
    awsCloudProviders,
    clusterAddonsByClusterName,
    machineDeploymentsByNamespaceAndCluster,
    machinePoolsByNamespaceAndCluster,
    clusterVersions,
    eksClusterVersions,
    clusterUpgradeJobsByCluster,
  ): ICapiClusterSelector[] => {
    const latestAwsClusterVersion = getLatestClusterVersion(clusterVersions, 'kubeVersion')
    const latestEksClusterVersion = getLatestClusterVersion(eksClusterVersions, 'name')

    return rawCapiClusters.map((cluster) => {
      const name = cluster.metadata?.name
      const namespace = cluster.metadata?.namespace
      const infrastructureRef = cluster.spec?.infrastructureRef
      const infrastructureType = getInfrastructureType(infrastructureRef?.kind)
      const { host: controlPlaneHost, port: controlPlanePort } =
        cluster.spec?.controlPlaneEndpoint || {}
      const infrastructure = getInfrastructureData(
        infrastructureRef,
        capiAwsClustersByNamespaceAndName,
        awsManagedControlPlanesByNamespaceAndName,
      )
      const controlPlane = getControlPlaneData(
        cluster.spec?.controlPlaneRef,
        nodeletControlPlanesByNamespaceAndName,
        awsManagedControlPlanesByNamespaceAndName,
      )
      const identityRef = infrastructure?.identityRef
      const cloudProvider = getCloudProvider(identityRef, awsCloudProviders)
      const addons = clusterAddonsByClusterName?.[cluster.metadata?.name] || []
      const hasDashboardAddon = addons.find(
        (addon) =>
          addon?.type === ClusterAddonType.KubernetesDashboard && addon?.phase === 'Installed',
      )
      const hasMonitoringAddon = addons.find(
        (addon) => addon?.type === ClusterAddonType.Monitoring && addon?.phase === 'Installed',
      )
      const hasProfileAgentAddon = addons.find(
        (addon) => addon?.type === ClusterAddonType.ProfileAgent && addon?.phase === 'Installed',
      )
      const dashboardLink = hasDashboardAddon ? getCapiK8sDashboardLink(cluster) : null
      const grafanaLink = hasMonitoringAddon ? getCapiGrafanaLink(cluster) : null
      const machineDeployments = machineDeploymentsByNamespaceAndCluster[namespace]?.[name] || []
      const machinePools = machinePoolsByNamespaceAndCluster[namespace]?.[name] || []
      const allNodeGroups = [...machineDeployments, ...machinePools]

      const version = controlPlane?.k8sVersion || controlPlane?.version
      // Can upgrade cluster if current version < latestClusterVersion
      const canUpgrade =
        compareVersions(
          version,
          infrastructureType === AwsClusterTypes.EKS
            ? latestEksClusterVersion
            : latestAwsClusterVersion,
        ) === -1
      const upgradeJobs = clusterUpgradeJobsByCluster[name] || []
      // upgrade jobs are sorted in descending order by creating timestamp
      // in clusterUpgradeJobsByCluster selector. Newest job is at the top
      const recentUpgradeJob =
        upgradeJobs.length > 0 ? head<IClusterUpgradeJobSelector>(upgradeJobs) : null
      const upgradeFailed = recentUpgradeJob?.phase === 'Failed'
      const upgradeCompleted = recentUpgradeJob?.phase === 'Completed'
      const currentUpgradeJob = upgradeFailed || upgradeCompleted ? null : recentUpgradeJob
      const upgrading = !!currentUpgradeJob

      const infraStatusConditions = [
        ...(cluster?.status?.conditions || []),
        ...(infrastructure?.status?.conditions || []),
      ]
      const controlPlaneStatusConditions = controlPlane?.status?.conditions || []
      const infraErrors = infraStatusConditions
        .filter((c) => castFuzzyBool(c.status) === false)
        .sort(sortStatusCondsBySeverity)
      const controlPlaneErrors = controlPlaneStatusConditions
        .filter((c) => castFuzzyBool(c.status) === false)
        .sort(sortStatusCondsBySeverity)

      return {
        ...cluster,
        uuid: cluster.metadata?.uid,
        name,
        namespace,
        creationTimestamp: cluster.metadata?.creationTimestamp,
        podsCidrBlocks: cluster.spec?.clusterNetwork?.pods?.cidrBlocks,
        servicesCidrBlocks: cluster.spec?.clusterNetwork?.services?.cidrBlocks,
        phase: capitalizeString(cluster.status?.phase),
        controlPlaneReady: cluster.status?.controlPlaneReady,
        infrastructureReady: cluster.status?.infrastructureReady,
        controlPlaneEndpoint:
          controlPlaneHost && controlPlanePort ? `${controlPlaneHost}:${controlPlanePort}` : '',
        controlPlaneEndpointHost: controlPlaneHost,
        controlPlaneEndpointPort: String(controlPlanePort),
        clusterType: ClusterTypes.Capi,
        infrastructureType,
        privileged: controlPlane?.privileged,
        allowWorkloadsOnMaster: controlPlane?.allowWorkloadsOnMaster,
        version,
        vpcCidrBlock: controlPlane?.vpcCidrBlock,
        replicas: controlPlane?.replicas || {},
        infrastructureRef: cluster.spec?.infrastructureRef,
        controlPlaneRef: cluster.spec?.controlPlaneRef,
        identityRef,
        infrastructure,
        controlPlane,
        resources: [
          cluster,
          ...(controlPlane?.resources || []),
          ...(infrastructure?.resources || []),
        ].filter((r) => !isNilOrEmpty(r)),
        cloudProviderId: cloudProvider?.uuid,
        region: infrastructure?.region,
        links: {
          dashboard: dashboardLink,
        },
        usage: {
          grafanaLink: grafanaLink,
        },
        // for consistancy between both legacy and qbert clusters
        kubeRoleVersion: version,
        cloudProviderType: cloudProvider?.descriptiveType,
        enableProfileAgent: !!hasProfileAgentAddon,
        machineDeployments,
        machinePools,
        allNodeGroups,
        canUpgrade,
        upgradeJobs,
        // Recent upgrade job is the most recent one. Could be in progress, failed or completed.
        // Current upgrade job is the upgrade that is still in progress
        recentUpgradeJob,
        currentUpgradeJob,
        upgrading,
        upgradeFailed,
        upgradeCompleted,
        infraErrors,
        controlPlaneErrors,
      }
    })
  },
)
const clusterSelectorDefaultParams: CapiClusterParams = {
  orderBy: 'created_at',
  orderDirection: 'desc',
}
export const makeParamsCapiClustersSelector = (
  defaultParams = clusterSelectorDefaultParams,
): AppSelector<ICapiClusterSelector[]> => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(capiClustersSelector, selectParams, (clusters, params) => {
    const { provisionedClusters, kubevirtClusters, healthyClusters } = params
    return pipe<
      ICapiClusterSelector[],
      ICapiClusterSelector[],
      ICapiClusterSelector[],
      ICapiClusterSelector[]
    >(
      filterIf(provisionedClusters, hasProvisionedClusters),
      filterIf(kubevirtClusters, kubevirtCluster),
      filterIf(healthyClusters, controlPlaneIsReady),
    )(clusters)
  })
}

export const awsAmisSelector = createSharedSelector(
  getDataSelector<DataKeys.AwsAmis>(DataKeys.AwsAmis),
  (amis: Ami[]): IAwsAmiSelector[] => {
    return amis.map((ami) => ({
      ...ami,
      os: ami.spec?.os,
      imageId: ami?.spec?.imageID,
      name: ami.metadata?.name,
      version: ami?.spec?.kubernetesVersion,
    }))
  },
)
