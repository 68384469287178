import React from 'react'
import Text from 'core/elements/Text'
import { path } from 'ramda'
import { makeStyles } from '@material-ui/styles'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import { IK8sNodeSelector, INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import Theme from 'core/themes/model'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Tooltip from 'core/elements/tooltip'

const useStyles = makeStyles<Theme>((theme) => ({
  title: {
    display: 'inline-grid',
    gridAutoColumns: 'minmax(min-content, max-content)',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gap: 8,
  },
  link: {
    display: 'inherit',
    color: theme.palette.primary.main,
  },
  actionIcon: {
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& i': {
      fontSize: 22,
    },
  },
}))

const renderRemoteSupport = (className) => (
  <Tooltip
    message={
      "Advanced Remote Support is currently enabled on this node. To disable it, select the 'Configure Remote Support' action from the actions bar."
    }
  >
    <div className={className}>
      <FontAwesomeIcon>headset</FontAwesomeIcon>
    </div>
  </Tooltip>
)

export default function NodeDetailCell({ item: node }: GridCellProps<INodesSelector>) {
  const classes = useStyles()
  const hasSupportRole = !!path(['combined', 'supportRole'], node)
  return (
    <Text className={classes.title} variant="body2" component="p">
      {hasSupportRole && (
        <span className={classes.link}>{renderRemoteSupport(classes.actionIcon)}</span>
      )}
      <SimpleLink src={routes.nodes.detail.path({ id: node.uuid })}>{node.name}</SimpleLink>
    </Text>
  )
}
export const EksNodeDetailCell = ({ item: node }: GridCellProps<IK8sNodeSelector>) => {
  const classes = useStyles()
  return (
    <Text className={classes.title} variant="body2" component="p">
      <SimpleLink src={routes.eksNodes.detail.path({ id: node.uuid })}>{node.name}</SimpleLink>
    </Text>
  )
}
