import { makeStyles } from '@material-ui/styles'
import Alert from 'core/components/Alert'
import SubmitButton from 'core/components/buttons/SubmitButton'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import TextField from 'core/components/validatedForm/TextField'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import Text from 'core/elements/Text'
import downloadFile from 'core/utils/downloadFile'
import React, { useState } from 'react'
import Modal from 'core/elements/modal'
import ApiClient from 'api-client/ApiClient'
import Button from 'core/elements/button/Button'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import RadioFields from 'core/components/validatedForm/radio-fields'

const { sunpike } = ApiClient.getInstance()
const maxDialogWidth = 800

const options = [
  {
    value: 'token',
    label: 'Token',
  },
  {
    value: 'password',
    label: 'Password',
  },
]

const CapiKubeconfigDownloadModal = ({ cluster, open = false, onClose }) => {
  if (!cluster) return null
  const classes = useStyles()
  const [authMethod, setAuthMethod] = useState('token')
  const [errorMessage, setErrorMessage] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [kubeConfigFile, setKubeConfigFile] = useState()
  const [toPreviewKubeconfig, setToPreviewKubeconfig] = useState(false)

  const { downloadKubeconfig } = sunpike

  const handleSubmit = async (params) => {
    setErrorMessage(null)
    setSubmitting(true)
    const { username, password } = params
    // For token auth method, only need to send X-Auth-Token in API request header
    const body =
      authMethod === 'password'
        ? {
            username,
            password,
          }
        : {}
    const { error, response } = await downloadKubeconfig(cluster?.name, body, cluster?.namespace)

    if (error) {
      setSubmitting(false)
      return setErrorMessage(typeof error === 'string' ? error : 'Error getting the Kubeconfig')
    }
    setKubeConfigFile(response)

    if (response) {
      downloadFile({
        filename: `${cluster?.name}-kubeconfig.yaml`,
        contents: response,
      })
    }

    setSubmitting(false)
  }

  return (
    <Modal
      open={open}
      title="Download Kubeconfig"
      onClose={onClose}
      maxWidth={maxDialogWidth}
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </>
      }
    >
      <ValidatedForm onSubmit={handleSubmit} fullWidth elevated={false}>
        <div className={classes.formBody}>
          <div className={classes.authMethod}>
            <Text variant="caption1">Authentication Method</Text>
            <RadioFields
              id="method"
              options={options}
              value={authMethod}
              onChange={(value: string) => setAuthMethod(value)}
            />
          </div>

          {authMethod === 'password' && (
            <>
              <TextField id="username" label="Enter your Username" required />
              <TextField id="password" label="Enter your Password" type="password" required />
              <br />
            </>
          )}
          {!!errorMessage && (
            <div className={classes.errorContainer}>
              <Alert variant="error" message={errorMessage} />
            </div>
          )}
          <div className={classes.buttonBar}>
            <SubmitButton type="submit" loading={submitting}>
              {authMethod === 'token' ? 'Download Config' : 'Validate + Download Config'}
            </SubmitButton>
            <CheckboxField
              id="previewKubeconfig"
              label="Preview Kubeconfig"
              onChange={(value) => setToPreviewKubeconfig(value)}
              value={toPreviewKubeconfig}
            />
          </div>
          <Alert
            variant={authMethod === 'token' ? 'primary' : 'warning'}
            title="Note:"
            message={
              authMethod === 'token'
                ? 'Token authentication is the preferred method for downloading kubeconfig. The kubeconfig will remain valid for the next 24 hours.'
                : 'Password authentication is less secure than token authentication, but the kubeconfig will remain functional for as long as the username and password are valid.'
            }
          />
        </div>
        <br />
        {!!kubeConfigFile && toPreviewKubeconfig && (
          <CodeMirror
            id="kubeconfigYaml"
            showCopyButton
            label={cluster?.name}
            maxHeight={400}
            value={kubeConfigFile}
            className={classes.overflowScroll}
          />
        )}
      </ValidatedForm>
    </Modal>
  )
}

export default CapiKubeconfigDownloadModal

const useStyles = makeStyles((theme) => ({
  formBody: {
    display: 'grid',
    gridAutoFlow: 'row',
    gap: 16,
    justifyItems: 'start',
  },
  authMethod: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    alignItems: 'center',
    gap: 32,
  },
  errorContainer: {
    paddingLeft: '12px',
  },
  buttonBar: {
    display: 'flex',
    alignItems: 'baseline',
    gap: 8,
  },
  overflowScroll: {
    maxWidth: maxDialogWidth - 60,
    overflowX: 'auto',
  },
}))
