import { routes } from 'core/utils/routes'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import React, { useMemo, useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router'
import { capiClustersSelector } from '../selectors'
import { IMachineDeploymentSelector, Phase } from '../machine-deployment/model'
import {
  NodeGroupsNotFullyHealthyAlert,
  UnhealthyNodeGroupsAlert,
} from './CapiClusterUpgradeAlerts'
import { AwsClusterTypes } from '../model'
import { ascend, isNil, map, prop, sortWith } from 'ramda'
import useListAction from 'core/hooks/useListAction'
import { useAppSelector } from 'app/store'
import { compareVersions } from 'k8s/util/helpers'
import KubernetesVersionField from '../../form-components/KubernetesVersionField'
import { listMachineDeployments } from '../machine-deployment/actions'
import { listMachinePools } from '../machine-pool/actions'
import Grid, { GridViewColumn } from 'core/elements/grid/Grid'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import { listAwsManagedMachinePools } from '../aws-managed-machine-pool/actions'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { makeFilteredMachineDeploymentsSelector } from '../machine-deployment/selectors'
import { makeFilteredAwsManagedMachinePoolsSelector } from '../aws-managed-machine-pool/selectors'
import { IAwsManagedMachinePoolSelector } from '../aws-managed-machine-pool/model'
import { makeFilteredAwsMachinePoolsSelector } from '../aws-machine-pool/selectors'
import { IAwsMachinePoolSelector } from '../aws-machine-pool/model'
import { NodeUpdateStrategyTypes } from '../../aws/capi/CapiMachineDeploymentNodeUpdateStrategies'
import Timeline from 'core/components/Timeline'
import { uncamelizeString } from 'utils/misc'
import NodeGroupNameCell from '../../cluster-cells/NodeGroupNameCell'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import DocumentMeta from 'core/components/DocumentMeta'
import { clientActions } from 'core/client/clientReducers'
import EditNodeGroupButton from './EditNodeGroupButton'
import Progress from 'core/components/progress/Progress'
import { listCapiClusters } from '../actions'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { createGridStatusCell, StatusCellModel } from 'core/elements/grid/cells/GridStatusCell'
import { getPhaseStatus } from '../details/node-groups/helpers'
import Card from 'core/elements/card'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createClusterUpgradeJob } from './actions'
import { listClusterAddons, listClusterVersions } from '../../cluster-addons/new-actions'
import { clusterAddonsSelector, clusterVersionsSelector } from '../../cluster-addons/selectors'
import Alert from 'core/components/Alert'
import { listEksVersions } from '../../../versions/eks/actions'
import { eksVersionsSelector } from '../../../versions/eks/selectors'
import { IMachinePoolSelector } from '../machine-pool/model'
import { IAwsManagedControlPlaneSelector } from '../control-plane/aws-managed-control-plane/model'

type NodeGroup = IMachineDeploymentSelector | IMachinePoolSelector

const machineDeploymentsSelector = makeFilteredMachineDeploymentsSelector()
const awsManagedMachinePoolsSelector = makeFilteredAwsManagedMachinePoolsSelector()
const awsMachinePoolsSelector = makeFilteredAwsMachinePoolsSelector()

const formatUpdateStrategyType = (value) => uncamelizeString(value || '')
const formatUpdateValueType = (value) =>
  value === NodeUpdateStrategyTypes.Percentage ? 'Percentage' : 'Count'

const filterDeprecatedPmkVersions = (clusterVersion) => clusterVersion?.phase !== 'Deprecated'
const filterDeprecatedEksVersions = (version) => !version?.deprecated
const machineDeploymentColumns = [
  { key: 'name', label: 'Name', CellComponent: NodeGroupNameCell },
  {
    key: 'phase',
    label: 'Phase',
    accessor: (md) => md,
    CellComponent: createGridStatusCell({
      dataFn: (nodeGroup: IMachineDeploymentSelector): StatusCellModel => {
        const phase = !!nodeGroup?.metadata?.deletionTimestamp ? 'Deleting' : nodeGroup?.phase
        return getPhaseStatus(phase)
      },
    }),
  } as GridViewColumn<IMachineDeploymentSelector, 'status'>,
  { key: 'numNodes', label: 'Nodes' },
  { key: 'updateStrategy', label: 'Strategy', formatFn: formatUpdateStrategyType },
  { key: 'maxSurgeType', label: 'Max Surge', formatFn: formatUpdateValueType },
  { key: 'maxSurge', label: 'Max Surge Value' },
  { key: 'maxUnavailableType', label: 'Max Unavailable', formatFn: formatUpdateValueType },
  { key: 'maxUnavailable', label: 'Max Unavailable Value' },
  {
    // Temp solution for now. Replace this when Xan is finsihed adding the single item action
    // in the grid
    key: 'none',
    label: '',
    width: 8,
    render: (_, nodeGroup) => <EditNodeGroupButton nodeGroup={nodeGroup} />,
  },
]

const awsMachinePoolColumns = [
  { key: 'name', label: 'Name', CellComponent: NodeGroupNameCell },
  {
    key: 'phase',
    label: 'Phase',
    accessor: (mp) => mp,
    CellComponent: createGridStatusCell({
      dataFn: (mp: IAwsMachinePoolSelector): StatusCellModel => {
        const phase = !!mp?.metadata?.deletionTimestamp ? 'Deleting' : mp?.phase
        return getPhaseStatus(phase)
      },
    }),
  } as GridViewColumn<IAwsMachinePoolSelector, 'status'>,
  { key: 'numNodes', label: 'Nodes' },
  {
    key: 'updateStrategy',
    label: 'Strategy',
    formatFn: formatUpdateStrategyType,
  },
  { key: 'minHealthyType', label: 'Minimum Healthy', formatFn: () => 'Percentage' },
  { key: 'minHealthyPercentage', label: 'Minimum Healthy Value' },
  {
    // Temp solution for now. Replace this when Xan is finsihed adding the single item action
    // in the grid
    key: 'none',
    label: '',
    width: 8,
    render: (_, nodeGroup) => <EditNodeGroupButton nodeGroup={nodeGroup} />,
  },
]

const awsManagedMachinePoolColumns = [
  { key: 'name', label: 'Name', CellComponent: NodeGroupNameCell },
  {
    key: 'phase',
    label: 'Phase',
    accessor: (mp) => mp,
    CellComponent: createGridStatusCell({
      dataFn: (mp: IAwsManagedMachinePoolSelector): StatusCellModel => {
        const phase = !!mp?.metadata?.deletionTimestamp ? 'Deleting' : mp?.phase
        return getPhaseStatus(phase)
      },
    }),
  } as GridViewColumn<IAwsManagedMachinePoolSelector, 'status'>,
  { key: 'numNodes', label: 'Nodes' },
  { key: 'maxUnavailableType', label: 'Max Unavailable', formatFn: formatUpdateValueType },
  {
    key: 'maxUnavailablePrecentage',
    label: 'Max Unavailable Value',
    formatFn: (_, mp) =>
      mp.maxUnavailableType === NodeUpdateStrategyTypes.Count
        ? mp?.maxUnavailable
        : mp?.maxUnavailablePrecentage,
  },
  {
    // Temp solution for now. Replace this when Xan is finsihed adding the single item action
    // in the grid
    key: 'none',
    label: '',
    width: 8,
    render: (_, nodeGroup) => <EditNodeGroupButton nodeGroup={nodeGroup} />,
  },
]

// Remove v from beginning of version
const cleanVersionStr = (version = '') =>
  version.length >= 1 && version[0] === 'v' ? version.slice(1) : version

const getRequiredVersion = (versionString) => {
  if (!versionString) return null
  const fullVersion = versionString.split('-')[0]
  const version = parseFloat(fullVersion)
  return (version - 0.01).toFixed(2)
}

// Get the version that is 1 higher than the current version
// Ex. getNextVersion('1.24.3') would return 1.25
const getNextVersion = (versionString): string => {
  if (!versionString) return null
  const fullVersion = versionString.split('-')[0]
  const version = parseFloat(fullVersion)
  return (version + 0.01).toFixed(2)
}

const isLessThanOrEqualToTargetVer = (targetVersion: string, version: string): boolean => {
  const fullVersion = cleanVersionStr(version).split('-')[0]
  const versions = fullVersion.split('.')
  const majorVersion = versions[0]
  const minorVersion = versions[1]
  return `${majorVersion}.${minorVersion}` <= targetVersion
}

const getNextUpgradeVersion = (currVersion: string, allVersions: string[]): string => {
  const targetVersion = getNextVersion(currVersion)
  return allVersions.reduce((acc, version) => {
    if (
      compareVersions(acc, version) === -1 &&
      isLessThanOrEqualToTargetVer(targetVersion, version)
    ) {
      return version
    }
    return acc
  }, currVersion)
}

const getTimelineData = (currentVersion = '', allVersions = []): [string[], number] => {
  // Remove patch and build numbers from the verisons
  const versions: string[] = allVersions.reduce((accum, v) => {
    const version = v.split('-')[0]
    if (!version) return accum
    const [major, minor, patch] = version.split('.')
    if (!major && !minor) return accum
    const finalVersion = major + '.' + minor
    if (!accum.includes(finalVersion)) {
      accum.push(finalVersion)
    }
    return accum
  }, [])

  const currVer = currentVersion.split('-')[0] || ''
  const currMajorAndMinorVer = currVer
    .split('.')
    .slice(0, 2)
    .join('.')

  const currVerIdx = versions.indexOf(currMajorAndMinorVer)

  return [versions, currVerIdx + 1]
}

const getEksAddons = (cluster) => {
  const controlPlane: IAwsManagedControlPlaneSelector = cluster.controlPlane
  return cluster.controlPlane?.addons
}

export default function CapiClusterUpgradePage() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { match, history } = useReactRouter()
  const { id } = match.params
  const [activeTab, setActiveTab] = useState('machineDeployments')
  const [upgradeTargetVersion, setUpgradeTargetVersion] = useState(null)

  const { loading: loadingCapiClusters } = useListAction(listCapiClusters)
  const clusters = useSelector(capiClustersSelector)
  const cluster = clusters?.find((x) => x.uuid === id)
  const isEksCluster = cluster?.infrastructureType === AwsClusterTypes.EKS

  const { loading: loadingMachineDeployments, reload: reloadMachineDeployments } = useListAction(
    listMachineDeployments,
  )
  const { loading: loadingMachinePools, reload: reloadMachinePools } = useListAction(
    listMachinePools,
  )
  const {
    loading: loadingAwsmanagedMachinePools,
    reload: reloadAwsManagedMachinePools,
  } = useListAction(listAwsManagedMachinePools)

  const machineDeployments = useSelectorWithParams(machineDeploymentsSelector, {
    clusterName: cluster?.name,
  })
  const awsMachinePools = useSelectorWithParams(awsMachinePoolsSelector, {
    clusterName: cluster?.name,
  })
  const awsManagedMachinePools = useSelectorWithParams(awsManagedMachinePoolsSelector, {
    clusterName: cluster?.name,
  })

  const machinePools = cluster?.machinePools || []

  const { loading: loadingClusterAddons } = useListAction(listClusterAddons, {
    params: {
      clusterId: cluster?.uuid,
      clusterName: cluster?.name,
      clusterNamespace: cluster?.namespace,
    },
  })

  const addons = useSelectorWithParams(clusterAddonsSelector, { clusterId: cluster?.uuid })

  const { update: startClusterUpgrade, updating: startingUpgrade, error } = useUpdateAction(
    createClusterUpgradeJob,
  )

  const numMachineDeployments = useMemo(() => machineDeployments.length, [machineDeployments])
  const numAwsMachinePools = useMemo(() => awsMachinePools.length, [awsMachinePools])
  const numAwsManagedMachinePools = useMemo(() => awsManagedMachinePools.length, [
    awsManagedMachinePools,
  ])

  const { loading: loadingClusterVersions } = useListAction(listClusterVersions)
  const { loading: loadingEksVersions } = useListAction(listEksVersions)
  const clusterVersions = useAppSelector(clusterVersionsSelector).filter(
    filterDeprecatedPmkVersions,
  )
  const eksVersions = useAppSelector(eksVersionsSelector).filter(filterDeprecatedEksVersions)

  const versions = useMemo(
    () =>
      isEksCluster
        ? eksVersions.map(({ name }) => name).sort()
        : map(({ version }) => version, sortWith([ascend(prop('kubeVersion'))], clusterVersions)),
    [isEksCluster, clusterVersions],
  )
  const currentVersion = isEksCluster ? cluster?.version : cleanVersionStr(cluster?.version)

  const latestVersion = useMemo(
    () => versions.reduce((acc, version) => (acc > version ? acc : version), ''),
    [versions],
  )

  const nextUpgradeVersion = useMemo(() => getNextUpgradeVersion(currentVersion, versions), [
    currentVersion,
    versions,
  ])

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        id: cluster?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [cluster?.name, id])

  useEffect(() => {
    setUpgradeTargetVersion(nextUpgradeVersion)
  }, [nextUpgradeVersion])

  const customizeVersionOptions = useCallback(
    (options) => {
      if (isNil(options)) return []
      return options.reduce((accum, option) => {
        const { value: version } = option
        // If version is <= current cluster version, remove it from options
        if (compareVersions(version, currentVersion) < 1) return accum

        // Disable option is option (version) > the next possible upgrade version
        if (compareVersions(version, nextUpgradeVersion) === 1) {
          const requiredVersion = getRequiredVersion(version)
          accum.push({
            ...options,
            label: `${version} (requires v${requiredVersion})`,
            disabled: true,
          })
        } else {
          accum.push(option)
        }

        return accum
      }, [])
    },
    [currentVersion, nextUpgradeVersion],
  )

  const nodeGroups: NodeGroup[] = useMemo(() => [...machineDeployments, ...machinePools], [
    machineDeployments,
    machinePools,
  ])
  const hasUnhealthyNodeGroups = useMemo(
    () => !!nodeGroups.find((group) => group?.phase === Phase.Failed),
    [nodeGroups],
  )
  const hasPartiallyHealthyNodeGroups = useMemo(
    () => !!nodeGroups.find((group) => group?.phase !== Phase.Running),
    [nodeGroups],
  )

  const handleSubmit = useCallback(
    async ({ targetVersion }) => {
      const { namespace, name: clusterName, upgradeJobs, version } = cluster
      const eksAddons = isEksCluster ? getEksAddons(cluster) : undefined
      const { success, response } = await startClusterUpgrade({
        namespace,
        clusterName,
        addons,
        eksAddons,
        nodeGroups,
        upgradeJobs,
        targetVersion,
        sourceVersion: version,
      })
      if (success) {
        history.push(
          routes.cluster.capi.upgradeProgress.path({
            id: cluster?.uuid,
            jobId: response?.metadata?.uid,
          }),
        )
      }
    },
    [cluster?.upgradeJobs, cluster?.uuid, addons, nodeGroups, isEksCluster],
  )

  const loading = loadingMachineDeployments || loadingMachinePools || loadingAwsmanagedMachinePools

  const reload = useCallback(() => {
    reloadMachinePools(true)
    reloadMachineDeployments(true)
    reloadAwsManagedMachinePools(true)
  }, [])

  const [timelineVersions, activeStep] = useMemo(() => {
    return getTimelineData(currentVersion, versions)
  }, [currentVersion, versions])

  const loadingData =
    loadingCapiClusters ||
    loadingMachineDeployments ||
    loadingMachinePools ||
    loadingAwsmanagedMachinePools ||
    loadingClusterAddons

  return (
    <>
      <DocumentMeta title="CAPI Cluster Upgrade" breadcrumbs />
      {error && <Alert variant="error" title={error?.title} message={error?.message} />}
      {hasPartiallyHealthyNodeGroups && !hasUnhealthyNodeGroups && (
        <NodeGroupsNotFullyHealthyAlert />
      )}
      {hasUnhealthyNodeGroups && <UnhealthyNodeGroupsAlert />}
      <br />
      <ValidatedForm onSubmit={handleSubmit} className={classes.validatedForm}>
        <Card
          footer={
            <div className={classes.actionsContainer}>
              <Button
                variant="secondary"
                onClick={() => history.push(routes.cluster.capi.list.path())}
              >
                Back
              </Button>
              <Button disabled={hasUnhealthyNodeGroups} loading={startingUpgrade}>
                Upgrade
              </Button>
            </div>
          }
        >
          <FormFieldSection title="Select Version" step={1}>
            <Text variant="body2" className={classes.infoText}>
              Cluster upgrades can only be performed to the next available version. The newest
              possible version <b>{nextUpgradeVersion}</b> is selected by default.
            </Text>
            <Text variant="body2">
              <b>Please note:</b> Control Plane nodes will be upgraded by one master node at a time.
            </Text>
            <div className={classes.timelineContainer}>
              <Timeline items={timelineVersions} activeStep={activeStep} />
              <div className={classes.currAndLatestVersions}>
                <Text variant="body2">
                  Current Version: <b>{currentVersion}</b>
                </Text>
                <Text variant="body2">
                  Latest Version: <b>{latestVersion}</b>
                </Text>
              </div>
            </div>

            <KubernetesVersionField
              id="targetVersion"
              wizardContext={{ targetVersion: upgradeTargetVersion }}
              setWizardContext={({ targetVersion }) => setUpgradeTargetVersion(targetVersion)}
              showAwsEksVersions={isEksCluster}
              customizeOptionsFn={customizeVersionOptions}
              selectFirst={false}
            />
          </FormFieldSection>
          <FormFieldSection title="Check Node Groups" step={2}>
            <Text variant="body2" className={classes.infoText}>
              Check the health of the node groups and if needed, make all the necessary changes in
              the upgrade strategy of each node group.
            </Text>
            <Progress loading={loadingData} renderContentOnMount={false}>
              <Tabs activeTab={activeTab} setActiveTab={(tab) => setActiveTab(tab)}>
                <Tab
                  label={`Machine Deployment (${numMachineDeployments})`}
                  value="machineDeployments"
                >
                  <Grid<IMachineDeploymentSelector>
                    data={machineDeployments}
                    loading={loading}
                    columns={machineDeploymentColumns}
                    uniqueIdentifier="uuid"
                    onReload={reload}
                    disableToolbar
                    compact
                  />
                </Tab>
                <Tab label={`Machine Pools (${numAwsMachinePools})`} value="machinePools">
                  <Grid<IAwsMachinePoolSelector>
                    data={awsMachinePools}
                    loading={loading}
                    columns={awsMachinePoolColumns}
                    uniqueIdentifier="uuid"
                    onReload={reload}
                    disableToolbar
                    compact
                  />
                </Tab>
                {isEksCluster && (
                  <Tab
                    label={`AWS Managed Machine Pools (${numAwsManagedMachinePools})`}
                    value="awsManagedMachinePools"
                  >
                    <Grid<IAwsManagedMachinePoolSelector>
                      data={awsManagedMachinePools}
                      loading={loading}
                      columns={awsManagedMachinePoolColumns}
                      uniqueIdentifier="uuid"
                      onReload={reload}
                      disableToolbar
                      compact
                    />
                  </Tab>
                )}
              </Tabs>
            </Progress>
          </FormFieldSection>
        </Card>
      </ValidatedForm>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  clusterName: {
    fontWeight: 'normal',
  },
  validatedForm: {
    '& .content': {
      marginLeft: '0px',
    },
  },
  timelineContainer: {
    maxWidth: '720px',
    marginTop: theme.spacing(1),
  },
  currAndLatestVersions: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(4, 0),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  infoText: {
    margin: theme.spacing(2, 0),
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}))
