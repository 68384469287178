import Bugsnag from '@bugsnag/js'
import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'

const { sunpike } = ApiClient.getInstance()

const supportedRoleVersionActions = ActionsSet.make<DataKeys.EksVersions>({
  uniqueIdentifier: 'uuid',
  entityName: entityNamesByKey.EksVersions,
  cacheKey: DataKeys.EksVersions,
  cache: false,
})

export const listEksVersions = supportedRoleVersionActions.add(
  new ListAction<DataKeys.EksVersions, { identityName: string; roleArn: string }>(
    async (params) => {
      const { identityName, roleArn } = params
      Bugsnag.leaveBreadcrumb('Attempting to EKS verions for identity', params)
      return await sunpike.getEksVersions(identityName, roleArn)
    },
  ),
)
