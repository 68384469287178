import { pathEq, propSatisfies } from 'ramda'
import { isFalse, isTruthy, pathStrOr } from 'utils/fp'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import { clockDriftDetectedInNodes } from 'app/plugins/infrastructure/components/nodes/helpers'
import { ClusterCreateTypes, ClusterTypes } from './model'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import { isTransientStatus, hasConvergingNodes } from './ClusterStatusUtils'
import { BadgeVariant } from 'core/elements/badge/Badge'
import { StatusCellModel } from 'core/elements/grid/cells/GridStatusCell'
import { routes } from 'core/utils/routes'

export const convertBareOSNodeTypeToCloudProviderType = (clusterType, defalutValue = '') => {
  if (!clusterType) {
    // TODO: what default should we have if we can't match anything?
    return defalutValue || clusterType
  }
  if (clusterType?.includes('virtual')) {
    return CloudProviders.VirtualMachine
  }
  if (clusterType?.includes('physical')) {
    return CloudProviders.PhysicalMachine
  }
  return defalutValue || clusterType
}

export const clusterIsHealthy = (cluster) =>
  cluster.status === 'ok' && cluster.masterNodesHealthStatus === 'healthy'

export const clusterIsUnhealthy = (cluster) => cluster.healthStatus === 'unhealthy'

export const isBareOsCluster = (cluster) => cluster.cloudProviderType === CloudProviders.BareOS

export const clusterNotBusy = (cluster) =>
  cluster.taskStatus === 'success' && !hasConvergingNodes(cluster.nodes)

export const isBareOsMultiMasterCluster = (cluster) =>
  cluster.cloudProviderType === CloudProviders.BareOS &&
  (!!cluster.masterVipIpv4 || !!cluster.externalDnsName)

export const isAzureAutoscalingCluster = (cluster) =>
  cluster.cloudProviderType === CloudProviders.Azure && cluster.enableCAS

export const canScaleMasters = ([cluster]) =>
  isBareOsMultiMasterCluster(cluster) && clusterIsHealthy(cluster) && clusterNotBusy(cluster)

export const canForceScaleMasters = ([cluster]) =>
  isBareOsMultiMasterCluster(cluster) && clusterIsUnhealthy(cluster)

export const canScaleWorkers = ([cluster]) =>
  clusterNotBusy(cluster) && !isAzureAutoscalingCluster(cluster)

export const canForceScaleWorkers = ([cluster]) =>
  isBareOsCluster(cluster) && clusterIsUnhealthy(cluster)

export const canUpgradeCluster = ([cluster]) =>
  !!cluster &&
  cluster.canUpgrade &&
  !isTransientStatus(cluster.taskStatus) &&
  !clockDriftDetectedInNodes(cluster.nodes)

export const canUpgradeCapiCluster = ([cluster]) =>
  cluster?.canUpgrade &&
  cluster?.phase &&
  !cluster?.upgrading &&
  !isTransientStatus(cluster.phase.toLowerCase()) &&
  cluster?.infrastructureReady &&
  cluster?.controlPlaneReady

export const canDeleteCluster = ([cluster]) =>
  !['creating', 'deleting'].includes(cluster.taskStatus)

export const canDeleteCapiCluster = ([cluster]) =>
  !['deleting'].includes(cluster?.phase?.toLowerCase())

export const notBusy = ([cluster]) => cluster.taskStatus !== 'updating'

export const getFormTitle = (title, target) => {
  if (target === ClusterCreateTypes.OneClick) {
    return `${title} Cluster`
  } else if (target === ClusterCreateTypes.Capi || target === ClusterCreateTypes.EksCapi) {
    return `Create a ${title}`
  }
  return `Create a ${title} Cluster`
}

export const getEtcdBackupPayload = (path, data) => {
  if (pathStrOr(0, path, data)) {
    const body: any = {
      storageType: 'local',
      isEtcdBackupEnabled: 1,
      storageProperties: {
        localPath: data.etcdStoragePath,
      },
    }
    if (data.useTimestampBackup) {
      body.dailyBackupTime = data.etcdBackupTimestamp
      body.maxTimestampBackupCount = data.maxTimestampBackups
    }
    if (data.useIntervalBackup) {
      if (data.intervalBackupUnit === 'minutes') {
        body.intervalInMins = data.etcdBackupIntervalMinutes
      } else if (data.intervalBackupUnit === 'hours') {
        body.intervalInHours = data.etcdBackupIntervalHours
      }
      body.maxIntervalBackupCount = data.maxIntervalBackups
    }
    return body
  } else {
    return {
      isEtcdBackupEnabled: 0,
    }
  }
}

export const getMetalLbCidr = (keyValueArr = []) =>
  keyValueArr.map(({ key, value }) => `${key}-${value}`).join()

export const hasMasterNode = propSatisfies(isTruthy, 'hasMasterNode')
export const hasHealthyMasterNodes = propSatisfies(
  (healthyMasterNodes: any) => healthyMasterNodes.length > 0,
  'healthyMasterNodes',
)
export const masterlessCluster = propSatisfies(isTruthy, 'masterless')
export const importedHasPrometheusTag = pathEq(
  ['metadata', 'labels', 'pf9-system_monitoring'],
  'true',
)
export const prometheusCluster = propSatisfies(isTruthy, 'hasPrometheus')
export const nonPrometheusCluster = propSatisfies(isFalse, 'hasPrometheus')
export const ecoCluster = propSatisfies(isTruthy, 'ecoInstalled')
export const kubevirtCluster = propSatisfies(isTruthy, 'hasKubevirt')

export const formatErrorWithClusterParams = (e: Error, clusterId) => ({
  ...(e || {}),
  params: { clusterId },
})

export const getClusterStatus = (nodes: INodesSelector[]): StatusCellModel => {
  const numNodes = nodes.length
  if (numNodes === 0) return { variant: 'unknown', label: 'Unknown' }
  const offlineNodes = nodes.reduce(
    (accum: number, node) =>
      node.ready === 'False' || node.ready === 'Unknown' ? accum + 1 : accum,
    0,
  )
  const percentOffline = offlineNodes / numNodes

  let status: BadgeVariant = 'success'
  let label = 'Healthy'
  if (percentOffline === 1) {
    status = 'error'
    label = 'Offline'
  } else if (percentOffline >= 0.75) {
    status = 'danger'
    label = 'Critical'
  } else if (percentOffline < 0.75 && percentOffline >= 0.5) {
    status = 'danger'
    label = 'Degraded'
  } else if (percentOffline < 0.5 && percentOffline > 0) {
    status = 'danger'
    label = 'Impacted'
  }

  const tooltipBody =
    offlineNodes === 0
      ? 'All nodes are healthy and ready to accept pods'
      : `${offlineNodes} out of ${numNodes} nodes are not healthy`

  return { variant: status, label, tooltipBody }
}

export const getClusterDetailsPath = ({ clusterId, clusterType, params = {} }) => {
  if (clusterType === ClusterTypes.Normal) {
    return routes.cluster.legacy.detail.path({ id: clusterId, ...params })
  } else if (clusterType === ClusterTypes.Imported) {
    return routes.cluster.imported.details.path({ id: clusterId, ...params })
  } else {
    return routes.cluster.capi.details.path({ id: clusterId, ...params })
  }
}
