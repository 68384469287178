import Card from 'core/elements/card'
import { propOr, head } from 'ramda'
import React, { useMemo } from 'react'
import { ICapiClusterSelector } from '../../model'
import Badge from 'core/elements/badge'
import Text from 'core/elements/Text'
import { clusterPhaseMap } from '../../CapiClustersListPage'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Divider from 'core/elements/Divider'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'

const SummaryBlock = ({ title, value }) => {
  const classes = useStyles()
  return (
    <div className={classes.summaryColumn}>
      <Text variant="body2" className={classes.contentTitle}>
        {title}
      </Text>
      <Text variant="caption1" className={classes.contentText}>
        {value}
      </Text>
    </div>
  )
}

const K8sVersionSummaryBlock = ({ version, upgrading = false }) => {
  const classes = useStyles()
  return (
    <div className={classes.summaryColumn}>
      <Text variant="body2" className={classes.contentTitle}>
        Kubernetes Version
      </Text>
      {upgrading ? (
        <div className={classes.upgradingStatus}>
          <FontAwesomeIcon size="md" solid spin>
            circle-notch
          </FontAwesomeIcon>
          <Text variant="body2">Upgrading</Text>
        </div>
      ) : (
        <Text variant="caption1" className={classes.contentText}>
          {version}
        </Text>
      )}
    </div>
  )
}

export default function CapiClusterOverviewSummary({ cluster }: { cluster: ICapiClusterSelector }) {
  const { name: clusterName, version, phase, controlPlane, upgrading } = cluster
  const { region, cloudProviderName } = propOr({}, 'infrastructure', cluster)
  const lastbackup = useMemo<string>(
    () => propOr('', 'completionTime', head(controlPlane?.etcdBackupStatus || [])),
    [cluster],
  )
  const classes = useStyles()

  const title = (
    <div className={classes.headerContainer}>
      <div className={classes.header}>
        <Text variant="subtitle2">{clusterName}</Text>
        <Badge text={phase || ''} variant={clusterPhaseMap[phase] || 'unknown'} />
      </div>
      <Text variant="body1">Amazon Web Services</Text>
    </div>
  )

  return (
    <Card title={title} className={classes.summaryContainer}>
      <div className={classes.summaryContent}>
        <K8sVersionSummaryBlock version={version} upgrading={upgrading} />
        <Divider className={classes.vertical}></Divider>
        <SummaryBlock title="Provider" value={cloudProviderName} />
        <Divider className={classes.vertical}></Divider>
        <SummaryBlock title="Region" value={region} />
        {lastbackup && (
          <>
            <Divider className={classes.vertical}></Divider>
            <SummaryBlock
              title="Last Backup"
              value={<DateAndTime value={lastbackup} showToolTip={false} />}
            />
          </>
        )}
      </div>
    </Card>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  headerContainer: {
    padding: '16px 32px',
  },
  header: {
    display: 'flex',
    alignItems: 'baseline',
    gap: theme.spacing(1),
  },
  summaryContainer: {
    maxWidth: 800,
    height: 300,
    margin: theme.spacing(1, 0),
  },
  summaryContent: {
    display: 'flex',
    background: theme.components.frame.background,
  },
  summaryColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    margin: '18px 24px 32px 24px',
  },
  contentTitle: {
    color: theme.components.typography.passive,
  },
  vertical: {
    border: 'none',
    borderLeft: `1px solid ${theme.components.input.frame.border}`,
    height: 50,
  },
  upgradingStatus: {
    display: 'flex',
    gap: theme.spacing(1),
    '& > i': {
      color: theme.palette.primary.main,
    },
  },
}))
