/* eslint-disable max-len */
import React, { FC, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'

import { bareOSSingleMasterSetupDocsLink } from 'k8s/links'
import ExternalLink from 'core/components/ExternalLink'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import WizardStep from 'core/components/wizard/WizardStep'

import ClusterNameField from '../../form-components/NameField'
import KubernetesVersionField from '../../form-components/KubernetesVersionField'

import Theme from 'core/themes/model'
import NetworkStack from '../../form-components/NetworkStackField'
import PrivilegedContainers from '../../form-components/PrivilegedContainersField'
import AllowWorkloadsOnMasterField from '../../form-components/AllowWorkloadsOnMasterField'
import { AddonManagerAddon, AddonTogglers } from '../../cluster-addons/cluster-addon-manager'
import AdvancedApiConfigFields from '../../form-components/AdvancedApiConfigFields'
import { allPass } from 'ramda'
import { masterNodeLengthValidator, requiredValidator } from 'core/utils/fieldValidators'
import ContainerAndServicesCIDRField from '../../form-components/container-and-services-cidr'
import NetworkBackendField, { NetworkBackendTypes } from '../../form-components/NetworkBackendField'
import CalicoNetworkFields, {
  CalicoDetectionTypes,
} from '../../form-components/CalicoNetworkFields'
import TagsField, { FormattedTags } from '../../form-components/TagsField'
import { capitalizeString, castBoolToStr } from 'utils/misc'
import BareOsClusterReviewTable from '../BareOsClusterReviewTable'
import { ClusterCreateTypes } from '../../model'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import { bareOSClusterTracking } from '../../tracking'
import AddNodeStep from '../../AddNodeStep'
import CustomApiFlagsFields from '../../form-components/CustomApiFlagsFields'
import NodeRegistrationChooserField from '../../form-components/NodeRegistrationChooserField'
import PicklistField from 'core/components/validatedForm/DropdownField'
import ContainerRuntimePicklist from '../../form-components/ContainerRuntimePicklist'
import { compareVersions } from 'k8s/util/helpers'
import { etcdBackupDefaults } from '../../cluster-addons/etcd-backup'
import {
  networkingStackFilter,
  excludeNodes,
  isUnassignedNode,
  isConnected,
} from 'app/plugins/infrastructure/components/nodes/helpers'
import ApiFqdnField from 'app/plugins/infrastructure/components/clusters/form-components/ApiFqdnField'

export const initialContext = {
  containersCidr: '10.20.0.0/16',
  servicesCidr: '10.21.0.0/16',
  networkPlugin: 'calico',
  calicoIpIpMode: 'Always',
  calicoNatOutgoing: true,
  calicoBlockSize: '26',
  runtimeConfigOption: 'default',
  mtuSize: 1440,
  privileged: true,
  etcdBackup: true,
  prometheusMonitoringEnabled: true,
  allowWorkloadsOnMaster: true,
  tags: [],
  networkStack: 'ipv4',
  calicoIPv4: 'autodetect',
  calicoIPv6: 'none',
  calicoDetectionMethod: CalicoDetectionTypes.FirstFound,
  useHostname: false,
  nodeRegistrationType: 'ipAddress',
  enableProfileAgent: true,
  containerRuntime: 'containerd',
  ...etcdBackupDefaults,
}

interface Props {
  wizardContext: any
  setWizardContext: any
  onNext: any
}

const clusterAddons: AddonManagerAddon[] = [
  { addon: 'etcdBackup' },
  { addon: 'enableMetallbLayer2' },
  { addon: 'enableMetal3' },
  { addon: 'monitoring' },
  { addon: 'networkPluginOperator' },
  { addon: 'kubevirtPluginOperator' },
  { addon: 'profileAgent' },
]

const advancedClusterAddons: AddonManagerAddon[] = [{ addon: 'enableTopologyManager' }]

const trackingFields = {
  platform: CloudProviders.VirtualMachine,
  target: ClusterCreateTypes.SingleMaster,
}

const VirtualSingleMasterCluster: FC<Props> = ({
  onNext,
  wizardContext,
  setWizardContext,
  ...rest
}) => {
  const classes = useStyles({})

  useEffect(() => {
    if (compareVersions(wizardContext.kubeRoleVersion, '1.20') >= 0) {
      setWizardContext({ enableProfileAgent: true })
    } else {
      setWizardContext({ enableProfileAgent: false })
    }
  }, [wizardContext.kubeRoleVersion])

  return (
    <>
      <WizardStep
        stepId="basic"
        label="Initial Setup"
        onNext={bareOSClusterTracking.wZStepOne(trackingFields)}
        {...rest}
      >
        <ValidatedForm
          fullWidth
          classes={{ root: classes.validatedFormContainer }}
          initialValues={wizardContext}
          onSubmit={setWizardContext}
          triggerSubmit={onNext}
          elevated={false}
        >
          {({ setFieldValue, values }) => (
            <>
              {/* <PollingData loading={loading} onReload={reload} hidden /> */}
              {/* Cluster Name */}
              <FormFieldSection
                title={`Basic Settings`}
                link={
                  <ExternalLink textVariant="caption2" url={bareOSSingleMasterSetupDocsLink}>
                    BareOS Cluster Help
                  </ExternalLink>
                }
              >
                <ClusterNameField setWizardContext={setWizardContext} />
                <KubernetesVersionField
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                />
              </FormFieldSection>

              {/* Cluster Settings */}
              <FormFieldSection title="Application & Container Settings">
                <PrivilegedContainers
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                />
                <AllowWorkloadsOnMasterField setWizardContext={setWizardContext} />
                <PicklistField
                  DropdownComponent={ContainerRuntimePicklist}
                  id="containerRuntime"
                  label="Container Runtime"
                  tooltip="The container runtime for the cluster"
                  onChange={(value) => setWizardContext({ containerRuntime: value })}
                  value={wizardContext.containerRuntime}
                  kubeRoleVersion={wizardContext.kubeRoleVersion}
                  required
                />
              </FormFieldSection>
              <FormFieldSection title="Networking & Registration">
                <NetworkStack wizardContext={wizardContext} setWizardContext={setWizardContext} />
                <NodeRegistrationChooserField
                  values={values}
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                />
              </FormFieldSection>
            </>
          )}
        </ValidatedForm>
      </WizardStep>
      <WizardStep
        stepId="masters"
        label="Master Node"
        onNext={bareOSClusterTracking.wZStepTwo(trackingFields)}
        keepContentMounted={false}
      >
        <AddNodeStep
          wizardContext={wizardContext}
          setWizardContext={setWizardContext}
          onNext={onNext}
          title={'Select a node to add as a Master Node'}
          nodeFieldId={'masterNodes'}
          nodeSelection={'single'}
          nodeFilterFn={allPass([
            isConnected,
            isUnassignedNode,
            networkingStackFilter(wizardContext.networkStack),
          ])}
          nodeValidations={[masterNodeLengthValidator]}
          isSingleNodeCluster={false}
          pollForNodes
          required
        />
      </WizardStep>
      <WizardStep
        stepId="workers"
        label="Worker Nodes"
        onNext={bareOSClusterTracking.wZStepThree(trackingFields)}
        keepContentMounted={false}
      >
        <AddNodeStep
          wizardContext={wizardContext}
          setWizardContext={setWizardContext}
          onNext={onNext}
          title={'Select nodes to add as Worker Nodes'}
          nodeFieldId={'workerNodes'}
          nodeSelection={'multiple'}
          nodeFilterFn={allPass([
            isUnassignedNode,
            isConnected,
            excludeNodes(wizardContext.masterNodes),
          ])}
          nodeValidations={wizardContext.allowWorkloadsOnMaster ? null : [requiredValidator]}
          isSingleNodeCluster={false}
          pollForNodes
        />
      </WizardStep>
      <WizardStep
        stepId="addons"
        label="Cluster Addons"
        onNext={bareOSClusterTracking.wZStepFour(trackingFields)}
        keepContentMounted={false}
      >
        <ValidatedForm
          fullWidth
          classes={{ root: classes.validatedFormContainer }}
          initialValues={wizardContext}
          onSubmit={setWizardContext}
          triggerSubmit={onNext}
          withAddonManager
          elevated={false}
        >
          <FormFieldSection title="Cluster Add-Ons">
            <AddonTogglers
              addons={clusterAddons}
              wizardContext={wizardContext}
              setWizardContext={setWizardContext}
            />
          </FormFieldSection>
        </ValidatedForm>
      </WizardStep>
      <WizardStep
        stepId="network"
        label="Network Info"
        onNext={bareOSClusterTracking.wZStepFive(trackingFields)}
      >
        <ValidatedForm
          initialValues={wizardContext}
          onSubmit={setWizardContext}
          triggerSubmit={onNext}
          classes={{ root: classes.validatedFormContainer }}
          elevated={false}
        >
          {({ values }) => (
            <>
              <FormFieldSection title="Cluster API FQDN">
                <ApiFqdnField />
              </FormFieldSection>

              <FormFieldSection title="Cluster Networking Range">
                <ContainerAndServicesCIDRField values={values} />
              </FormFieldSection>

              <FormFieldSection title="Cluster CNI">
                <NetworkBackendField
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                />
                {values.networkPlugin === NetworkBackendTypes.Calico && (
                  <CalicoNetworkFields values={wizardContext} setValues={setWizardContext} />
                )}
              </FormFieldSection>
            </>
          )}
        </ValidatedForm>
      </WizardStep>
      <WizardStep
        stepId="advanced"
        label="Advanced"
        onNext={bareOSClusterTracking.wZStepSix(trackingFields)}
      >
        <ValidatedForm
          fullWidth
          classes={{ root: classes.validatedFormContainer }}
          initialValues={wizardContext}
          onSubmit={setWizardContext}
          triggerSubmit={onNext}
          elevated={false}
          withAddonManager
        >
          {({ values }) => (
            <>
              <FormFieldSection title="Advanced Configuration">
                <AdvancedApiConfigFields values={values} setWizardContext={setWizardContext} />
                <CustomApiFlagsFields
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                />
                <TagsField />
                <AddonTogglers
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  addons={advancedClusterAddons}
                />
              </FormFieldSection>
            </>
          )}
        </ValidatedForm>
      </WizardStep>
      <WizardStep
        stepId="review"
        label="Finalize & Review"
        onNext={bareOSClusterTracking.wZStepSeven(trackingFields)}
      >
        <ValidatedForm
          initialValues={wizardContext}
          onSubmit={setWizardContext}
          triggerSubmit={onNext}
          title="Finish and Review"
        >
          <BareOsClusterReviewTable wizardContext={wizardContext} columns={reviewTableColumns} />
        </ValidatedForm>
      </WizardStep>
    </>
  )
}

export default VirtualSingleMasterCluster

const reviewTableColumns = [
  { id: 'name', label: 'Cluster Name' },
  { id: 'kubeRoleVersion', label: 'Kubernetes Version', insertDivider: true },
  { id: 'masterNodes', label: 'Master Nodes' },
  { id: 'workerNodes', label: 'Worker Nodes' },
  { id: 'externalDnsName', label: 'API FQDN', insertDivider: true },
  { id: 'containersCidr', label: 'Containers CIDR' },
  { id: 'servicesCidr', label: 'Services CIDR' },
  {
    id: 'networkPlugin',
    label: 'CNI',
    render: (value) => capitalizeString(value),
    insertDivider: true,
  },
  { id: 'calicoIpIpMode', label: 'IP in IP Encapsulation Mode' },
  { id: 'calicoNatOutgoing', label: 'NAT Outgoing', render: (value) => castBoolToStr()(value) },
  { id: 'calicoBlockSize', label: 'Block Size' },
  { id: 'mtuSize', label: 'MTU Size' },
  {
    id: 'privileged',
    label: 'Privileged',
    render: (value) => castBoolToStr()(value),
    insertDivider: true,
  },
  {
    id: 'allowWorkloadsOnMaster',
    label: 'Enable workloads on master node',
    render: (value) => castBoolToStr()(value),
  },
  {
    id: 'prometheusMonitoringEnabled',
    label: 'Prometheus Monitoring',
    render: (value) => castBoolToStr()(value),
  },
  {
    id: 'runtimeConfigOption',
    label: 'Advanced API Configuration',
    render: (value) => capitalizeString(value),
  },
  {
    id: 'tags',
    label: 'Tags',
    renderArray: true,
    render: (value) => <FormattedTags tags={value} />,
  },
]

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
}))
