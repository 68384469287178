import Card from 'core/elements/card'
import React, { useEffect, useMemo, useState } from 'react'
import { ICapiClusterSelector } from '../../model'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { ArrayElement } from 'core/actions/Action'
import { clusterAddonsSelector } from '../../../cluster-addons/selectors'
import { listClusterAddons } from '../../../cluster-addons/new-actions'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { clusterAddonDisplayNames, isUninstalling } from '../../../cluster-addons/helpers'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import { getAddonHealthStatus } from '../../../cluster-addons/cluster-details-page/ClusterAddonsPage'
import AddonHealthStatusesCard from '../../../cluster-addons/cluster-details-page/AddonHealthStatusesCard'
import Grid from 'core/elements/grid/Grid'
import Button from 'core/elements/button'
import CardHeader from 'core/elements/card/CardHeader'
import { EtcdBackupStatus } from '../../control-plane/nodelet-control-plane/model'
import { calculateAge } from 'utils/misc'
import { switchCase } from 'utils/fp'
import CapiBackupsHealthStatusesCard from './CapiBackupsHealthStatusesCard'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import { getCapiClusterUpgradeStatus } from '../../upgrade/helpers'
import { IClusterUpgradeJobSelector } from '../../upgrade/model'
import moment from 'moment'
import { routes } from 'core/utils/routes'
import useReactRouter from 'use-react-router'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'

type SelectorModel = ArrayElement<ReturnType<typeof clusterAddonsSelector>>

const getBackUpsTypeStatus = (status) => {
  const variant = switchCase(
    {
      true: 'success',
      false: 'danger',
    },
    'unknown',
  )(status)
  return { variant, label: status ? 'Success' : 'Failed' }
}

const upgradesColumns = [
  {
    key: 'startTime',
    label: 'Time',
    formatFn: (value, item) => moment(value || item?.completionTime).fromNow(),
  },
  {
    key: 'phase',
    label: 'Status',
    CellComponent: createGridStatusCell({
      dataFn: getCapiClusterUpgradeStatus,
    }),
  },
  {
    key: 'targetVersion',
    label: 'Target Version',
  },
]

const addOnsColumns = [
  { key: 'type', label: 'Add-on Name', formatFn: (type) => clusterAddonDisplayNames[type] },
  {
    key: 'healthy',
    label: 'Health Status',
    accessor: (addon) => addon,
    CellComponent: createGridStatusCell({
      dataFn: getAddonHealthStatus,
    }),
  },
  { key: 'version', label: 'Version' },
]
const backUpsColumns = [
  { key: 'completionTime', label: 'Age', formatFn: (value) => calculateAge(value) },
  {
    key: 'startTime',
    label: 'Start Time',
    CellComponent: DateAndTime,
  },
  {
    key: 'succeeded',
    label: 'Status',
    CellComponent: createGridStatusCell({
      dataFn: getBackUpsTypeStatus,
    }),
  },
  { key: 'name', label: 'Name' },
]

export default function CapiClusterOverviewAddons({ cluster }: { cluster: ICapiClusterSelector }) {
  const { history } = useReactRouter()
  const { loading, message, reload } = useListAction(listClusterAddons, {
    params: {
      clusterId: cluster?.uuid,
      clusterName: cluster?.name,
      clusterNamespace: cluster?.namespace,
    },
  })

  const [tab, setTab] = useState('upgrades')
  const addons = useSelectorWithParams(clusterAddonsSelector, { clusterId: cluster?.uuid })
  const etcdBackupStatuses = cluster.controlPlane?.etcdBackupStatus || []

  const allAddons = useMemo(() => {
    const allAddons = addons.filter((addon) => !isUninstalling(addon))

    // const isOlderVersionedCluster = compareVersions(cluster?.version, '1.21') < 0 // not sure if the commented block is needed
    // if (cluster?.etcdBackupEnabled) {
    //   allAddons.push(createEtcdBackupAddonObj(cluster))
    // }
    return allAddons
  }, [addons, cluster])

  useEffect(() => {
    // Refetch the addons to get the latest statuses
    reload(true)
  }, [])

  const classes = useStyles()

  const title = (
    <CardHeader className={classes.headerContainer}>
      <Button
        className={classes.button}
        onClick={() => setTab('upgrades')}
        variant={tab === 'upgrades' ? 'tertiary' : 'secondary'}
        size="small"
      >
        Upgrades
      </Button>
      <Button
        className={classes.button}
        onClick={() => setTab('addons')}
        variant={tab === 'addons' ? 'tertiary' : 'secondary'}
        size="small"
      >
        Addons
      </Button>
      <Button
        className={classes.button}
        onClick={() => setTab('backups')}
        variant={tab === 'backups' ? 'tertiary' : 'secondary'}
        size="small"
      >
        Backups
      </Button>
    </CardHeader>
  )

  const upgradeJobsRowMenuItems: Array<GridRowMenuItemSpec<IClusterUpgradeJobSelector>> = [
    {
      icon: 'square-chevron-right',
      handleClick: ({ id }) =>
        history.push(routes.cluster.capi.upgradeProgress.path({ id: cluster?.uuid, jobId: id })),
    },
  ]

  return (
    <Card title={title} className={classes.container}>
      {tab === 'upgrades' && (
        <>
          <Grid<IClusterUpgradeJobSelector>
            data={cluster?.upgradeJobs || []}
            loading={loading}
            columns={upgradesColumns}
            uniqueIdentifier="name"
            onRefresh={reload}
            loadingMessage={message}
            rowMenuItems={upgradeJobsRowMenuItems}
            disableToolbar
            compact
          />
        </>
      )}
      {tab === 'addons' && (
        <>
          <AddonHealthStatusesCard addons={allAddons} />
          <br />
          <Grid<SelectorModel>
            data={allAddons}
            loading={loading}
            columns={addOnsColumns}
            uniqueIdentifier="type"
            onRefresh={reload}
            loadingMessage={message}
            disableToolbar
            compact
          />
        </>
      )}
      {tab === 'backups' && (
        <>
          <CapiBackupsHealthStatusesCard backups={etcdBackupStatuses} />
          <br />
          <Grid<EtcdBackupStatus>
            data={etcdBackupStatuses}
            loading={loading}
            columns={backUpsColumns}
            uniqueIdentifier="uid"
            disableToolbar
            compact
          />
        </>
      )}
    </Card>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  headerContainer: {
    display: 'flex',
    padding: theme.spacing(1.5, 1),
    justifyContent: 'flex-end',
  },

  button: {
    margin: theme.spacing(0, 0.5),
  },
  container: {
    margin: theme.spacing(1, 0, 0, 1),
    minWidth: 500,
    overflow: 'auto',
  },
}))
