import React from 'react'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import useReactRouter from 'use-react-router'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import { NodeGroupSelector } from '../capi/details/node-groups/CapiClustersNodeGroupsOverview'
import { Phase } from '../capi/machine-deployment/model'

export const getNodeGroupStatus = (phase) => {
  switch (phase) {
    case Phase.Running:
      return 'ok'
    case Phase.Failed:
      return 'fail'
    case Phase.ScalingUp:
      return 'pause'
    case Phase.ScalingDown:
      return 'pause'
    case Phase.Deleting:
      return 'error'
    default:
      return 'unknown'
  }
}

export default function NodeGroupNameCell({ item: nodeGroup }: GridCellProps<NodeGroupSelector>) {
  const { match } = useReactRouter()
  const clusterId = match.params.id
  const tab = match.params.tab
  const { name } = nodeGroup
  return (
    <SimpleLink src={routes.cluster.capi.nodeGroups.path({ id: clusterId, tab, name })}>
      {name}
    </SimpleLink>
  )
}
